.fullsize-video-bg {
    background-color: rgba(114,45,0, 1);
    position:relative;
    max-height: calc(100vh - 120px)!important;
    border: 1px $color1;
    overflow:hidden;
    @include responsivo(tablet) {
height: auto!important;
    } 
}
.video {
    background: rgba(114,45,0,.35);
    outline: 3px solid  rgb(29, 12, 0);
    max-width:100vw; 
    min-width:100vw!important; 
    height: auto;
    @include responsivo(tablet) {
    
    }
    @include responsivo(medium-screens) {
        min-width:100vw!important;
       
    }


}
.footer {
    display: flex;
    background:#552d00;
    justify-content: center;
    background-size: cover;
    position:relative;
    flex-direction: column;
    padding: 10px;
 
    overflow-y: visible;
    width:100vw;
    mix-blend-mode: initial;
    @include responsivo(medium-screens) {
        padding: 20px;
        max-height:120px;
  
    }

}
.footercontent {
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    
    @include responsivo(medium-screens) {
        flex-direction: row;

    }
}
.footer {
    background-image: url(../img/footer-bg-mob.jpg);
    @include responsivo(medium-screens) {
        background-image: url(../img/footer-bg.jpg);
    }
}
.footer-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    padding-top: 5px;
    line-height: 20px;
    margin-left:10px;
    color:#fff;
    font-family: 'Adobe Caslon Pro Bold Italic';
    @include responsivo(medium-screens) {
        display: block;
    }
    p {}

}
.logo-footer {
    margin-right:10px;
    height:50px;
    padding-left: 13px;
    @include responsivo(medium-screens) {
        height:80px;
    }

}
.redes {
    margin-top:5px;
    margin-bottom:5px;
display:flex;
flex-direction: row;
#face {
    margin-left:5px;
}
img {
    height:30px;
}
}

.fullsize-video-bg { height: 100%; overflow: hidden; }

.fullsize-video-bg:before { content: ""; background: rgba(114,45,0, 0.30); position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0; }
.fullsize-video-bg:after { content: ""; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgTWFjaW50b3NoIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjI4RkZBQTgzNzg1NzExRTU4NTQyODc3OUM4MTZGMUREIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjI4RkZBQTg0Nzg1NzExRTU4NTQyODc3OUM4MTZGMUREIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjhGRkFBODE3ODU3MTFFNTg1NDI4Nzc5QzgxNkYxREQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjhGRkFBODI3ODU3MTFFNTg1NDI4Nzc5QzgxNkYxREQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz66uHInAAAAIUlEQVR42mL5//8/AyMj42YGIGBigABfEMEIkoEBgAADAKvuBwVS8BAjAAAAAElFTkSuQmCC); background-size: 2px 2px; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; }