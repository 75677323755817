.alimentacao {

height:100%;
    width: 100%;
    overflow: hidden;

    @include responsivo(medium-screens) {


  
    }
 
}



.alim-fix {
position:absolute;

margin-top:-10px;

    top:40vh;
    left:-20px;
   
    height:100px;
    width:calc(100vw + 40px);

    transform-origin: bottom center;

background-color: transparent;

    animation: float-movement 9s ease-in-out infinite;
    animation-direction: alternate-reverse;
    animation-play-state: running;
    animation-delay: 0;
   mix-blend-mode: color-burn;

    }
.alim-fix2 {
position:absolute;
z-index:2;
margin-top:0px;

    top:40vh;
    left:0;

    height:120px;
    width:100vw;

    transform-origin: bottom center;
opacity:0.2;
background-color: transparent;

    }



.alim-fix-bg {

    position:absolute;
    height:80px;
    padding-top:-10px;
    width:inherit;
    top:40vh;
opacity: 0.2;


    }
.ali-top {
    
    position: absolute;
    top:-30px;
    left:0;
    height: 100px;
    z-index:2;
    width: auto;


}
.alimentacao-bg {

    position: relative;
    display: flex;
    height: 25vh;
    width: 100%;
    background-image: none;
    background-size: cover;

    
    overflow: hidden;
    @include responsivo(tablet) {
        background-image: none;
        position: relative;
    display: flex;
    height: 40vh;
    width: 100%;
    overflow: hidden;
    }
    @include responsivo(medium-screens) {
        background-image: none;
        position: relative;
    display: flex;
    height: 40vh;
    width: 100%;
    overflow: hidden;
    }
    .alimentacao-bg-img {
        position: absolute;
        width: 300%;
        will-change: transform;
        @media only screen and (max-width: 990px) { 
            margin-top:100px;
 
        }

        @include responsivo(tablet) {
            width: 350%;
        }
        @include responsivo(medium-screens) {
            width: 160%;
        }
        @include responsivo(screens) {max-width:120%;
        
        }
        @include responsivo(wide-screens) {max-width:120%; margin-top:150px;} 
    
    }

}

.alimentacao-i2 {
    position: relative;
    display:flex;
    justify-content: center;
    
    
    img {
        width:200px;
        height: 120px;
    }
    mix-blend-mode: color-burn;
    @include responsivo(medium-screens) { 
        margin-top:10px;
        position: absolute;
    right: 30px;
    bottom: 90px;
    mix-blend-mode: color-burn;
    img {
        width:100%;
        height: 100%;
    }

    }
    @include responsivo(screens) { 
        position: absolute;
    right: 80px;
    bottom: 100px;
    mix-blend-mode: color-burn;
    img {
        width:120%;
        height:120%;
    }

    }

}

.alimentacao-w {
    position: relative;
    display: flex;

    width: 100%;
z-index:-1;
    background-image:url("../img/alimbg.png"), url("../img/bg-header-mob.jpg");
    background-position: center bottom -30px, center;
    background-repeat: no-repeat;
    background-size: contain, cover;
    
    justify-content: center;

    align-items: stretch;
    @include responsivo(tablet) {
        background-image: url("../img/bg-header.jpg");
        justify-content: center;
    background-size: cover;
    align-items: center;

    }
    @include responsivo(medium-screens) {
        height:60vh;
        padding-top:100px;
        background-image: url("../img/bg-header.jpg");
        justify-content: center;
    background-size: cover;
    align-items: center;
    height:60vh;
    }
    .container {
        width:100%;
    }
}
.alimentacao-i {
   
    @include responsivo(wide-screens) {
        margin-top:140px;
    }
}
.img1q3 {
    position:relative;
    height: 100px;
    width: 100%;
    margin-top: 30px;
    margin-bottom:10px;
    background-image: url("../img/img1q3.png");
    --aspect-ratio: 1/2.5;
    background-repeat: no-repeat;
    background-size: contain;
 
    
    @include responsivo(tablet) {
        
        position: relative;
        margin-bottom:0px;
        height: 200px;
    width: 500px;
    margin-top: 60px;
    margin-left:100px;

    }
    @include responsivo(medium-screens) {
        
        position: relative;
        margin-bottom:30px;
        height: 200px;
    width: 600px;
    margin-top: 30px;
    margin-left:50px;

    }
    @include responsivo(wide-screens) {
        width: 710px;
    }

}

.txt3 {
    position:relative;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    padding:10px;
    font-family: 'Adobe Caslon Pro Italic';
    width: 100%;
    color: $color1;
    text-align: center;
  
 
    @include responsivo(tablet) {
        margin-bottom: 40px;
        margin-top: 40px;
    width:80%;
    padding:0px;
    margin-left:10%;
   
    }
    @include responsivo(medium-screens) {
        margin-bottom: 40px;
        margin-top: 40px;
    margin-left: 100px;
    padding:20px;
    text-align: left;
    }

    @include responsivo(screens) {
        margin-top: 40px;
    margin-left: 150px;
    padding:0px;
    text-align: left;
        width: 800px;
        
    }
    @include responsivo(wide-screens) {
        margin-top: 40px;
    margin-left: 150px;
    padding:0px;
    text-align: left;
        width: 800px;
        
    }

    h1 {
        font-family: 'Adobe Caslon Pro Bold Italic';
        font-size: 30px;

        @include responsivo(screens) {
            font-size: 36px;
        }
        @include responsivo(wide-screens) {
            font-size: 42px;
        }
    }

    p {
        br {  display: none;
            @include responsivo(medium-screens) {
                display: block;
            }}
        font-size: 18px;
        margin-top: 10px;
        line-height: 1.2;
       

        @include responsivo(tablet) {
            font-size: 20px;
        }
        @include responsivo(screens) {
            font-size: 22px;
        }
        @include responsivo(wide-screens) {
            font-size: 27px;
        }
    }
}
.img3q3 {
    position:absolute;
    height: 160px;
    width: 100%;
 right:0px;
 bottom:-70px;
    background-image: url("../img/img3q33.png");
    --aspect-ratio: 1/2.5;
    background-repeat: repeat;
    background-size: contain;
    animation: float-movement 9s ease-in-out infinite;
    animation-direction: alternate-reverse;
    animation-play-state: running;
    animation-delay: 0;
    transform-origin: bottom center;
    @include responsivo(tablet) {
        height: 280px;
        bottom:-160px;
    }
    @include responsivo(medium-screens) {
        height: 180px;
        bottom:-80px;
    }


}
@keyframes float-movement {
    0% {
        transform: skewX(0.1deg)
    }
    35% {
        transform: skewX(3deg)
    }
  
    70% {
        transform: skewX(-3deg)
    }
    
    100% {
        transform: skewX(0deg)
    }
  
    
  
 
    
}
