
  
  #contactForm { 
    display: none;
    z-index:99999;
    border: 6px solid $color1; 
    padding: 2em;
    width: 500px;
    text-align: center;
    background: $color1;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%)
 
  }
  .modal {
    p {
      color:#fff;
      font-family: 'Adobe Caslon Pro Italic';
      margin-bottom:5px;
      font-size: 18px;
    }
      
  }
  .logo-contato {

  }
  .contact-bg {
    display:none;
    z-index:99998;
    position:fixed;
    margin:0;  padding:0;
    left:0; top:0;
    height:100%;
    width:100%;
    background:rgba(255,255,255, 0.9);
  }
 
  .contacts {
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    img {
      height:30px; margin:10px 5px;
  }
  
  }
  