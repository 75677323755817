.animalogo, .animado6, .animado7, .animado8, .animado5, .parallax-bg, 
.animaq, .animaq2, .animaq3 {
  will-change: transform, opacity;
}
body {

    width:100%;
   overflow-x: hidden;
   height:initial;

}
body:before {
  
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("../img/bg-header-mob.jpg");

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  will-change: transform;
  @include responsivo(medium-screens) {

    background-image: url("../img/bg-header.jpg");

}
}
p {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;   
}

  ::-webkit-scrollbar {
    display: none;
}

section {
  will-change: transform;
  max-height:  100%!important;
 min-height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  @include responsivo(medium-screens) {
    height: 100vh!important;

}

  }

 
.pagination {
  z-index:1999;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  list-style: none;
  flex-direction: column;
  outline : none;

  @include responsivo(medium-screens) {
    right: 30px; }
  li {
   
    z-index:9999;
    position: relative;
    margin: 5px 0;
    background: #fff;
    border: 1px $color1 solid;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    color: $color1;
    transition: all .2s ease;
    outline : none;
    @include responsivo(medium-screens) {
    cursor: pointer;
   
    &:hover {
      transform: scale(1.2);
      .hover-text {
        opacity: 1;}
    }
      
      }
    :active {
      -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    }
    :active, :focus {
      outline: 0;
      border: none;
      --moz-outline-style: none;
    }
  }
  
}
  li.active {
    background:$color1;
    transform: scale(1.2);
    transition: all .3s ease;
  
    outline : none;
  
  
}

.hover-text {
  z-index: 999;
  position: absolute;
right: 5px;
top: -3px;
opacity: 0;
font-size:12px;
color:$color1;
-webkit-transition: opacity 0.2s ease;
transition: opacity 0.2s ease;
padding-right: 10px;
white-space: nowrap;
font-family: 'Adobe Caslon Pro Bold Italic';
outline : none;

}

.desktop {
  display:none;
  @include responsivo(medium-screens) {
    display:block;
  }
}
.mobile {
  display:block;
  @include responsivo(medium-screens) {
    display:none;
  }
}
// section img {
//   transition: 0.2s opacity ease-out;
//   opacity: .1;
// }
// section img.yall-loaded {
//   opacity: 1;
// }