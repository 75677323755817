
.queijos1 {
    position: relative;
    display: flex;
    height: 100vh;
    width: 100vw;
  
    background-image: url("../img/bg-header.jpg");
    background-size: cover;



    overflow: hidden;

    .containerq {

        padding:0px;
        padding-top:20px;
        padding-bottom:20px;
        will-change: opacity;
        height: 100%;
        width: 100%;
        align-self: center;
        display: grid;
        align-items: center;
        justify-content: center;


    
        grid-template:
        "txt" 3fr
        "img" 3fr
        "img2" 2fr
        /1fr;

        @include responsivo(tablet) {


        }

        @include responsivo(medium-screens) {
            padding-top:0px;
            padding-bottom:0px;
            height: 90%;
            width:90%;
            margin-left: 7.5%;
            display: grid;
            grid-gap: auto;
            grid-template:
            ". txt img" 4fr
            
            "img2 img2 img2" 3fr
            / 1fr 2fr 3fr;
    

        }

        @include responsivo(wide-screens) {
    width: 100%;
    margin-left:auto;
            height: 88%;
            grid-template:
            "txt img" 5fr
            "img2 img2" 4fr
            / 3fr 3fr;
    

        }
    }
    .txt1-w {grid-area: txt;
    display:flex;
    width:100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    @include responsivo(medium-screens) {
        padding-left:0%;
        align-items: center;
    }
    @include responsivo(wide-screens) {
        padding-left:20%;
    }
    }
    .txt1 {
        height: max-content;
        
 
        font-family: 'Adobe Caslon Pro Italic';

        color: $color1;

        text-align: center;

        @include responsivo(tablet) {

            position: relative;
            text-align: center;
   
        }

        @include responsivo(medium-screens) {

  
            text-align: left;
  
      
        }

        @include responsivo(screens) {

    
            text-align: left;

        }

        @include responsivo(wide-screens) {

        }

        h1 {
            font-family: 'Adobe Caslon Pro Bold Italic';
            font-size: 32px;

            @include responsivo(screens) {
                font-size: 36px;
            }

            @include responsivo(wide-screens) {
                font-size: 42px;
            }
        }

        p {
            font-size: 20px;
            margin-top: 10px;
            line-height: 1.2;

            @include responsivo(screens) {
                font-size: 22px;
            }

            @include responsivo(wide-screens) {
                font-size: 27px;
            }
        }
    }

    .img1q1-w {
        grid-area: img;
display:flex;
flex-direction: column;

        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;


        @include responsivo(tablet) {


    
    
            
        }
        @include responsivo(medium-screens) {

            height: 90%;
            width: 100%;

        }

        .img1q1 {
            width: 100%;
            height: 100%;
       
            background-image: url(../img/load.gif);
            opacity:0.8;
            background-size: 50px 50px;
            background-repeat: no-repeat;
            background-position: center;
            
      
        }

    }
}

.img2q1-w {
    position: relative;
    grid-area: img2;
    height: 90%;
    width: 100%;
    bottom: 10px;
    @include responsivo(tablet) {
  
        bottom: 0px;
        
    }

    @include responsivo(medium-screens) {

        bottom: 0px;
    }

    @include responsivo(screens) {


    }

    @include responsivo(wide-screens) {

    }

    .img2q1 {
        position: relative;
        width: 100%;
        height: 100%;

        background-image: url(../img/load.gif);
        opacity:0.8;
        background-size: 50px 50px;
        background-repeat: no-repeat;
        background-position: center;


        @include responsivo(tablet) {

        }

        @include responsivo(medium-screens) {

 
        }

        @include responsivo(wide-screens) {

        }
    }
}



.oqueijo-bg {

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
    width: 100%;



    @include responsivo(medium-screens) {

        background-image: none;
        height: 40vh;
    }

    overflow: hidden;


    .queijo-bg-img {
        display: none;
        position: absolute;
        max-width: 100%;


        left: 0px;

        @include responsivo(medium-screens) {
            display: block;


        }

        @include responsivo(wide-screens) {}
    }

    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 2s;
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

.oqueijo-bg.lazy-bg-loaded {

    background-image: url(../img/bg-queijo.jpg);
    background-size: cover;

    @include responsivo(medium-screens) {

        background-image: none;
    }
}