.mobile-nav ul {
    display:none;
    margin-top:10px;
    display: flex;
    flex-direction: row;
    width:100%;
    justify-content: center;
}
.mobile-nav ul li {

    color: $color1;
    font-family: $font1;
    margin:10px;
    font-size:18px;

}
.mobile-nav {
    display: block;
    @include responsivo(medium-screens) {display: none; }
}
.nav {
    display: none;
    position: absolute;
    @include responsivo(medium-screens) {display: flex; 
    left:50%;
    margin-left: 180px;
    margin-top:20px;
    }

    ul {
        display: flex;
    flex-direction: row;
    width:100%;
    justify-content: center;
    li {
      
        color: $color1;
        font-family: $font1;
        margin:10px;
      
       
            font-size:24px;
        }
    }
}

.bt-1, .bt-2 {
    cursor: pointer;
    z-index:9999;
    transition: all .2s ease;
    -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;   
    &:hover {
        color:#000;
    }
}
#nav, #pagination {
    visibility: hidden;
}