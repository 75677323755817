.maturacao {
    position:relative;
    background-image: url("../img/bg-header-mob.jpg");
    background-size: cover;
    width: 100%;
    height:auto;
    padding-bottom:25px;
  
    @include responsivo(medium-screens) { 
        height:100vh;
    padding-bottom:0px;
        min-height: 100%!important;
        background-image: url("../img/bg-header.jpg");
        background-position: 0vw 0vh;
    }

}
.maturacao-bg {
    position: relative;
    display: flex;
    height: 25vh;
    width: 100%;
    z-index:20;

    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    object-fit: none;
    min-width: 100%;
    overflow: hidden;
    backface-visibility: hidden; 
    @include responsivo(tablet) {
        height: 40vh;
        justify-content: center;
        align-items: baseline;
}
    @include responsivo(screens) {
        height: 40vh;
        justify-content: center;
        align-items: baseline;

}
@include responsivo(medium-screens) {
    justify-content: center;
    align-items: center;

    height: 40vh;

}
@include responsivo(wide-screens) {
    justify-content: start;
    align-items: center;

    height: 40vh;

}
}


.maturacao-bg-imgm {position:absolute;height: 400px; width:auto;margin-top:90px;margin-left:100px;}
    .maturacao-bg-img {
          backface-visibility: hidden; 
        position: absolute;
        height: auto;
        width: 2000px;

        will-change: transform;
        



    
        @include responsivo(medium-screens) {
            bottom:-211px;
            min-width: 2166px;
            max-width: 2166px;

        }
        @include responsivo(screens) {
            bottom:-170px;
            min-width: 2166px;
            max-width: 2166px;

        }
        @include responsivo(wide-screens) { 

            bottom:inherit;
            min-width: 2166px;
            max-width: 2166px;
            min-height: 57.78vh!important;
        } 
    
    }


.bg-matu-left {
    position: absolute;
 
  
    @include responsivo(medium-screens) {
        left: 0px;
        width: calc((100vw - 1025px) + (215px * 2.8))!important;
        max-width: 1000px;
        height:auto;

    }
    @include responsivo(screens) {
        left: 0px;
        width: calc((100vw - 1025px) + (225px * 1.5))!important;
        max-width: 530px;
        height:auto;

    }
    @include responsivo(wide-screens) {
    
        height:4.65vh;
        width: auto!important;
        
        max-width: 100%;

    }

}

.maturacao-bg.lazy-bg-loaded {

    background-image: url("../img/bg-maturacao2.jpg");
}
.maturacao-w.lazy-bg-loaded {
    
    background-image: url("../img/bg-header-mob.jpg");
}
.maturacao-w {
    position: relative;
    display: flex;
    width: 100%;
    height:auto;

  
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;

    @include responsivo(tablet) {

        height: 60vh;

    }
    
    @include responsivo(medium-screens) {

        padding-bottom: 0px;
        align-items: center;
        flex-direction: row;
        height: 60vh;

    }
    @include responsivo(screens) {

        padding-bottom: 0px;
        align-items: center;
        flex-direction: row;
        height: 60vh;

    }
} 
.img1q4m {
    height: 25vh;
    width: 100vw;
margin-top: 20px; 
margin-bottom: 40px; 
    background-image: url("../img/img111q4.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 9999;
}
.bg-matuw {
    width: 100%;
    max-height:60vh;
}
.bg-matu {
    
  
    @include responsivo(medium-screens) {
        
        position:absolute;
        top:37vh;
        
        width: 2270px!important;

        height:68vh;
        max-height:68vh;
        max-width:2270px;
        left:calc(((100vw - 1365px) / 2) - 467px);
     

    
        overflow:hidden;
    
        opacity:1;
   
    }
    @include responsivo(screens) {

        position:absolute;
        top:37vh;
        
        width: 2270px!important;
        left:calc(((100vw - 1365px) / 2) - 467px);
        height:68vh;
        max-height:68vh;
        max-width:2270px;
        

    
        overflow:hidden;
    
        opacity:1;


    }
    @include responsivo(wide-screens) {

        position:absolute;
        top:37vh;
        left:-120px;
        min-width: 2360px!important;
        height:63vh;
        max-height:63vh;
        max-width:2360px;
    
        overflow:hidden;
    
        opacity:1;
    
    
    
     
        z-index: 0;

    }

}
.container-flex {
       
z-index:9999;
    display:flex;
    flex-direction: column;
    min-height:max-content;

    justify-content: center;
    flex: 2 1 auto;
    @include responsivo(tablet) {
        height: 50vh;

    }
    @include responsivo(medium-screens) {
        height: 50vh;
        flex-direction: row;
    }
    @include responsivo(screens) {
        height: 60vh;
        flex-direction: row;
    }
    @include responsivo(wide-screens) {
        height: 60vh;
        flex-direction: row;
        justify-content: stretch;
    }
}
.maturacao-i {
    position:relative;
    z-index:9999;

 
    @include responsivo(screens) {    width:100%;
        height: 100%;}
}
.img1q4 {
   position: absolute;
    height: 32%;
    left:115vh;
    top:40vh;

    z-index: 9999;
   
    @include responsivo(medium-screens) {
        left:calc(((100vw - 1365px) / 2) + 800px);
        height: 30%;
        top: calc(80vh + 10%);
    }
    @include responsivo(screens) {
        height: 250px;
        top: calc(80vh - 100px);
        left:calc(((100vw - 1365px) / 2) + 800px);
       }
    @include responsivo(wide-screens) {
        height: 38%;
        top: calc(80vh - 19%);
        left:1050px;
       
    
        z-index: 9999;

    }

}

.img1q4mob {
   
    height: 100px;
    width: 100vw;

    background-image: url("../img/img1q4.png");
    background-size: contain;
    background-position: center;
    

    background-repeat: no-repeat;
    background-size: contain;
 
 

}

.txt4 {
overflow:visible;
 
    position: relative;
    font-family: 'Adobe Caslon Pro Italic';
    text-align: center;


padding:20px;
    color: $color1;
    

    @include responsivo(medium-screens) {

        margin-top: 0px;
        text-align: left;
     
        padding:30px;
    }
    @include responsivo(screens) { }
    @include responsivo(wide-screens) {margin-left: -10%; margin-top:-20px;}
    h1 {
        font-family: 'Adobe Caslon Pro Bold Italic';
        font-size: 32px;
        @include responsivo(screens) {font-size:36px;}
        @include responsivo(wide-screens) {font-size:42px;}
    }

    p {
        font-size: 18px;
        margin-top: 15px;
        line-height: 1.2;
        @include responsivo(screens) {margin-top: 10px;
            line-height: 1.2;font-size: 22px;}
        @include responsivo(wide-screens) {font-size:27px;}
    }
}
.cont-matu {
    
    z-index:9999;
    max-width:100vw;
    width:100%;
    height:60vh;
    display: grid;
    justify-content: center;
    align-items: center;
    @include responsivo(medium-screens) { 
        background-image: none;
        grid-template: 
        ". . ." 1fr
        ". txt ." auto
        ". . ." 1fr
        / 1fr auto 4fr;
    }

    @include responsivo(wide-screens) {

          display: grid;
          justify-content: center;
          align-items: center;
          grid-template: 
          ". . ." 1fr
          ". txt ." auto
          ". . ." 1fr
          / 1fr auto 4fr;

    }
}
.cont-matu-w {
    grid-area: txt;
    position: relative;
  width: 100%;

//   box-sizing: padding-box;
// padding:12
// px 10px;
// border: 10px solid transparent;

//   background-clip: content-box, border-box;
//   -moz-background-clip: content-box, border-box;
//   background-attachment: scroll;

//   background-origin: border-box;
//   background-position: 0vw 0vh, center center;
//   background-repeat: no-repeat, no-repeat;
//   background-color: transparent;

//   background-size: auto 100vh, 100% 100%;
   

}

.img2q4 {

    position: absolute;
    margin-left:50px;
    bottom: 0px;
    height: 100%;
    width: 300px;
    z-index: 9999;
    
    background-image: url("../img/img2q4.png");
    background-size: contain;
    background-position: center;
    
    background-repeat: no-repeat;

}