/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'Adobe Caslon Pro Regular';
  src: url("../fonts/ACaslonPro-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/ACaslonPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ACaslonPro-Regular.woff2") format("woff2"), url("../fonts/ACaslonPro-Regular.woff") format("woff"), url("../fonts/ACaslonPro-Regular.ttf") format("truetype"), url("../fonts/ACaslonPro-Regular.svg") format("svg");
  /* Legacy iOS */
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Adobe Caslon Pro Italic';
  src: url("../fonts/ACaslonPro-Italic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/ACaslonPro-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/ACaslonPro-Italic.woff2") format("woff2"), url("../fonts/ACaslonPro-Italic.woff") format("woff"), url("../fonts/ACaslonPro-Italic.ttf") format("truetype"), url("../fonts/ACaslonPro-Italic.svg") format("svg");
  /* Legacy iOS */
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Adobe Caslon Pro Bold Italic';
  src: url("../fonts/ACaslonPro-BoldItalic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/ACaslonPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/ACaslonPro-BoldItalic.woff2") format("woff2"), url("../fonts/ACaslonPro-BoldItalic.woff") format("woff"), url("../fonts/ACaslonPro-BoldItalic.ttf") format("truetype"), url("../fonts/ACaslonPro-BoldItalic.svg") format("svg");
  /* Legacy iOS */
  font-weight: normal;
  font-style: normal; }

.paraxify {
  background-attachment: fixed;
  background-position: center center;
  background-size: cover; }

.header {
  height: 100vh !important;
  position: relative;
  background-size: cover;
  overflow: hidden;
  z-index: 999; }

.fullscreen-bg__video {
  position: absolute;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  opacity: 0.4; }

.header-logo-h {
  display: flex;
  padding-top: 20px;
  flex-direction: column; }

.agrofloresta-ww {
  min-height: 60vh; }

.header-logo {
  will-change: opacity;
  position: relative;
  width: 300px;
  height: 280px;
  max-height: calc(100vh - 280px);
  max-width: 90%;
  background-image: url("../img/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  transition: all 0.5s ease;
  pointer-events: none; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .header-logo {
      width: 450px;
      height: 400px; } }
  @media only screen and (min-width: 959px) {
    .header-logo {
      width: 400px;
      height: 360px; } }
  @media only screen and (min-width: 1420px) {
    .header-logo {
      width: 500px;
      height: 460px;
      max-height: calc(100vh - 480px); } }

.montanhas {
  will-change: transform;
  position: absolute;
  bottom: 0px;
  height: 220px;
  width: 100%;
  min-width: 100vw;
  background-image: url("../img/montanha-mob.png");
  background-size: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .montanhas {
      background-image: url("../img/montanha.png");
      background-size: cover;
      height: 280px; } }
  @media only screen and (min-width: 959px) {
    .montanhas {
      background-image: url("../img/montanha.png");
      background-size: cover;
      width: 1200px; } }
  @media only screen and (min-width: 1420px) {
    .montanhas {
      height: 370px; } }

.montanhas-ilustracao {
  will-change: transform;
  position: absolute;
  bottom: 50px;
  height: 280px;
  width: 1200px;
  min-width: 100vw;
  left: -300px;
  background-image: url(../img/montanha-ilustracao.png);
  background-size: cover;
  mix-blend-mode: color-burn; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .montanhas-ilustracao {
      bottom: 100px; } }
  @media only screen and (min-width: 959px) {
    .montanhas-ilustracao {
      bottom: 0;
      left: 0; } }
  @media only screen and (min-width: 1420px) {
    .montanhas-ilustracao {
      bottom: 180px;
      left: 0; } }

.fullsize-video-bg {
  background-color: #722d00;
  position: relative;
  max-height: calc(100vh - 120px) !important;
  border: 1px #5f2900;
  overflow: hidden; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .fullsize-video-bg {
      height: auto !important; } }

.video {
  background: rgba(114, 45, 0, 0.35);
  outline: 3px solid #1d0c00;
  max-width: 100vw;
  min-width: 100vw !important;
  height: auto; }
  @media only screen and (min-width: 959px) {
    .video {
      min-width: 100vw !important; } }

.footer {
  display: flex;
  background: #552d00;
  justify-content: center;
  background-size: cover;
  position: relative;
  flex-direction: column;
  padding: 10px;
  overflow-y: visible;
  width: 100vw;
  mix-blend-mode: initial; }
  @media only screen and (min-width: 959px) {
    .footer {
      padding: 20px;
      max-height: 120px; } }

.footercontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media only screen and (min-width: 959px) {
    .footercontent {
      flex-direction: row; } }

.footer {
  background-image: url(../img/footer-bg-mob.jpg); }
  @media only screen and (min-width: 959px) {
    .footer {
      background-image: url(../img/footer-bg.jpg); } }

.footer-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  padding-top: 5px;
  line-height: 20px;
  margin-left: 10px;
  color: #fff;
  font-family: 'Adobe Caslon Pro Bold Italic'; }
  @media only screen and (min-width: 959px) {
    .footer-grid {
      display: block; } }

.logo-footer {
  margin-right: 10px;
  height: 50px;
  padding-left: 13px; }
  @media only screen and (min-width: 959px) {
    .logo-footer {
      height: 80px; } }

.redes {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row; }
  .redes #face {
    margin-left: 5px; }
  .redes img {
    height: 30px; }

.fullsize-video-bg {
  height: 100%;
  overflow: hidden; }

.fullsize-video-bg:before {
  content: "";
  background: rgba(114, 45, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }

.fullsize-video-bg:after {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgTWFjaW50b3NoIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjI4RkZBQTgzNzg1NzExRTU4NTQyODc3OUM4MTZGMUREIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjI4RkZBQTg0Nzg1NzExRTU4NTQyODc3OUM4MTZGMUREIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjhGRkFBODE3ODU3MTFFNTg1NDI4Nzc5QzgxNkYxREQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjhGRkFBODI3ODU3MTFFNTg1NDI4Nzc5QzgxNkYxREQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz66uHInAAAAIUlEQVR42mL5//8/AyMj42YGIGBigABfEMEIkoEBgAADAKvuBwVS8BAjAAAAAElFTkSuQmCC);
  background-size: 2px 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }

.mobile-nav ul {
  display: none;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center; }

.mobile-nav ul li {
  color: #5f2900;
  font-family: "Adobe Caslon Pro Italic";
  margin: 10px;
  font-size: 18px; }

.mobile-nav {
  display: block; }
  @media only screen and (min-width: 959px) {
    .mobile-nav {
      display: none; } }

.nav {
  display: none;
  position: absolute; }
  @media only screen and (min-width: 959px) {
    .nav {
      display: flex;
      left: 50%;
      margin-left: 180px;
      margin-top: 20px; } }
  .nav ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center; }
    .nav ul li {
      color: #5f2900;
      font-family: "Adobe Caslon Pro Italic";
      margin: 10px;
      font-size: 24px; }

.bt-1, .bt-2 {
  cursor: pointer;
  z-index: 9999;
  transition: all .2s ease;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none; }
  .bt-1:hover, .bt-2:hover {
    color: #000; }

#nav, #pagination {
  visibility: hidden; }

.queijos1 {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100vw;
  background-image: url("../img/bg-header.jpg");
  background-size: cover;
  overflow: hidden; }
  .queijos1 .containerq {
    padding: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    will-change: opacity;
    height: 100%;
    width: 100%;
    align-self: center;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template: "txt" 3fr "img" 3fr "img2" 2fr /1fr; }
    @media only screen and (min-width: 959px) {
      .queijos1 .containerq {
        padding-top: 0px;
        padding-bottom: 0px;
        height: 90%;
        width: 90%;
        margin-left: 7.5%;
        display: grid;
        grid-gap: auto;
        grid-template: ". txt img" 4fr  "img2 img2 img2" 3fr / 1fr 2fr 3fr; } }
    @media only screen and (min-width: 1420px) {
      .queijos1 .containerq {
        width: 100%;
        margin-left: auto;
        height: 88%;
        grid-template: "txt img" 5fr "img2 img2" 4fr / 3fr 3fr; } }
  .queijos1 .txt1-w {
    grid-area: txt;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    @media only screen and (min-width: 959px) {
      .queijos1 .txt1-w {
        padding-left: 0%;
        align-items: center; } }
    @media only screen and (min-width: 1420px) {
      .queijos1 .txt1-w {
        padding-left: 20%; } }
  .queijos1 .txt1 {
    height: max-content;
    font-family: 'Adobe Caslon Pro Italic';
    color: #5f2900;
    text-align: center; }
    @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
      .queijos1 .txt1 {
        position: relative;
        text-align: center; } }
    @media only screen and (min-width: 959px) {
      .queijos1 .txt1 {
        text-align: left; } }
    @media only screen and (min-width: 1025px) {
      .queijos1 .txt1 {
        text-align: left; } }
    .queijos1 .txt1 h1 {
      font-family: 'Adobe Caslon Pro Bold Italic';
      font-size: 32px; }
      @media only screen and (min-width: 1025px) {
        .queijos1 .txt1 h1 {
          font-size: 36px; } }
      @media only screen and (min-width: 1420px) {
        .queijos1 .txt1 h1 {
          font-size: 42px; } }
    .queijos1 .txt1 p {
      font-size: 20px;
      margin-top: 10px;
      line-height: 1.2; }
      @media only screen and (min-width: 1025px) {
        .queijos1 .txt1 p {
          font-size: 22px; } }
      @media only screen and (min-width: 1420px) {
        .queijos1 .txt1 p {
          font-size: 27px; } }
  .queijos1 .img1q1-w {
    grid-area: img;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%; }
    @media only screen and (min-width: 959px) {
      .queijos1 .img1q1-w {
        height: 90%;
        width: 100%; } }
    .queijos1 .img1q1-w .img1q1 {
      width: 100%;
      height: 100%;
      background-image: url(../img/load.gif);
      opacity: 0.8;
      background-size: 50px 50px;
      background-repeat: no-repeat;
      background-position: center; }

.img2q1-w {
  position: relative;
  grid-area: img2;
  height: 90%;
  width: 100%;
  bottom: 10px; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .img2q1-w {
      bottom: 0px; } }
  @media only screen and (min-width: 959px) {
    .img2q1-w {
      bottom: 0px; } }
  .img2q1-w .img2q1 {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../img/load.gif);
    opacity: 0.8;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: center; }

.oqueijo-bg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
  width: 100%;
  overflow: hidden;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s; }
  @media only screen and (min-width: 959px) {
    .oqueijo-bg {
      background-image: none;
      height: 40vh; } }
  .oqueijo-bg .queijo-bg-img {
    display: none;
    position: absolute;
    max-width: 100%;
    left: 0px; }
    @media only screen and (min-width: 959px) {
      .oqueijo-bg .queijo-bg-img {
        display: block; } }

@-webkit-keyframes slide {
  100% {
    left: 0; } }

@keyframes slide {
  100% {
    left: 0; } }

.oqueijo-bg.lazy-bg-loaded {
  background-image: url(../img/bg-queijo.jpg);
  background-size: cover; }
  @media only screen and (min-width: 959px) {
    .oqueijo-bg.lazy-bg-loaded {
      background-image: none; } }

.agrofloresta {
  width: 100%;
  overflow: hidden;
  background-image: url(../img/bg-header-mob.jpg);
  background-size: cover; }
  @media only screen and (min-width: 959px) {
    .agrofloresta {
      background-image: url(../img/bg-header.jpg); } }

.agrofloresta-bg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28vh;
  width: 100%;
  overflow: hidden;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 1s; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .agrofloresta-bg {
      background-image: none;
      height: 50vh; } }
  @media only screen and (min-width: 959px) {
    .agrofloresta-bg {
      background-image: none;
      height: 50vh; } }
  @media only screen and (min-width: 1025px) {
    .agrofloresta-bg {
      background-image: none;
      height: 40vh; } }
  .agrofloresta-bg .agrofloresta-bg-img {
    will-change: transform;
    position: absolute;
    max-width: 150%;
    margin-top: 150px;
    left: 0px; }
    @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
      .agrofloresta-bg .agrofloresta-bg-img {
        display: block;
        margin-top: 300px;
        max-width: 180%; } }
    @media only screen and (min-width: 959px) {
      .agrofloresta-bg .agrofloresta-bg-img {
        display: block;
        margin-top: 400px;
        max-width: 100%; } }
    @media only screen and (min-width: 1420px) {
      .agrofloresta-bg .agrofloresta-bg-img {
        margin-top: 580px; } }

@-webkit-keyframes slide {
  100% {
    left: 0; } }

@keyframes slide {
  100% {
    left: 0; } }

@media only screen and (min-width: 959px) {
  .agrofloresta-bg.lazy-bg-loaded {
    background-image: none; } }

.agrofloresta-i2-mob {
  position: relative;
  margin-top: 50px;
  margin-bottom: -30px;
  margin-left: 100px;
  width: 140px;
  height: 140px;
  background-image: url(../img/frutas.png);
  background-size: contain;
  background-repeat: no-repeat;
  mix-blend-mode: color-burn; }

.agrofloresta-i-mob {
  position: relative;
  height: 250px;
  width: 120%;
  margin-left: 10%;
  margin-top: -150px;
  margin-bottom: 30px;
  background-image: url("../img/img1q2-mob.png");
  --aspect-ratio: 1/2.5;
  background-repeat: no-repeat;
  background-size: contain;
  mix-blend-mode: color-burn; }

.agrofloresta-i2 {
  position: relative;
  mix-blend-mode: color-burn; }
  .agrofloresta-i2 img {
    mix-blend-mode: color-burn;
    position: absolute;
    margin-left: 80px; }
    @media only screen and (min-width: 959px) {
      .agrofloresta-i2 img {
        position: absolute;
        width: 200px;
        margin-left: 100px; } }
    @media only screen and (min-width: 1025px) {
      .agrofloresta-i2 img {
        position: absolute;
        width: auto;
        margin-left: 20px; } }
  @media only screen and (min-width: 959px) {
    .agrofloresta-i2 {
      position: absolute;
      right: 35%;
      top: 30px; } }

.agrofloresta-w {
  will-change: opacity;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center; }
  @media only screen and (min-width: 959px) {
    .agrofloresta-w {
      padding-left: 100px;
      max-height: 541px; } }
  @media only screen and (min-width: 1025px) {
    .agrofloresta-w {
      padding-left: 100px;
      max-height: 541px; } }
  @media only screen and (min-width: 1420px) {
    .agrofloresta-w {
      padding-left: 50px; } }
  .agrofloresta-w .container {
    display: flex;
    justify-content: center;
    background-size: cover;
    align-items: center;
    flex-direction: column; }
    @media only screen and (min-width: 959px) {
      .agrofloresta-w .container {
        align-items: center;
        flex-direction: row; } }

.agrofloresta-if {
  display: none; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .agrofloresta-if {
      display: flex;
      flex-direction: column;
      position: relative;
      left: 20%;
      top: -467px;
      width: 180%; } }
  @media only screen and (min-width: 959px) {
    .agrofloresta-if {
      display: flex;
      flex-direction: column;
      position: relative;
      left: -11%;
      top: -150px;
      width: 180%; } }
  @media only screen and (min-width: 1420px) {
    .agrofloresta-if {
      left: -25%;
      top: -200px;
      width: 180%; } }

.agrofloresta-f {
  height: 300px;
  overflow: hidden; }
  @media only screen and (min-width: 1420px) {
    .agrofloresta-f {
      height: 400px;
      margin-left: -4px; } }
  .agrofloresta-f .araucaria {
    z-index: 10;
    transform: rotate(3deg);
    width: 200px;
    left: 58px;
    top: -78px; }
    .agrofloresta-f .araucaria img {
      width: 500px;
      height: auto; }
      @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
        .agrofloresta-f .araucaria img {
          width: 660px;
          margin-top: -50px; } }
      @media only screen and (min-width: 1025px) {
        .agrofloresta-f .araucaria img {
          width: 600px; } }
      @media only screen and (min-width: 1420px) {
        .agrofloresta-f .araucaria img {
          width: 786px; } }
    @media only screen and (min-width: 1420px) {
      .agrofloresta-f .araucaria {
        width: 200px;
        left: 58px;
        top: -78px; } }

.agrofloresta-i {
  position: relative;
  left: 50px;
  top: 0px;
  right: 0; }
  @media only screen and (min-width: 1420px) {
    .agrofloresta-i {
      left: 50px;
      top: 0px;
      right: 0; } }

.img1q2 {
  height: 541px;
  width: 100%;
  background-image: url(../img/img0q2.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-blend-mode: color-burn;
  margin-left: -5px; }
  @media only screen and (min-width: 1420px) {
    .img1q2 {
      height: 541px;
      width: 180%;
      background-size: auto, cover; } }

.txt2 {
  position: relative;
  top: 20px;
  font-family: 'Adobe Caslon Pro Italic';
  width: 100%;
  color: #5f2900;
  text-align: center;
  padding: 15px; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .txt2 {
      text-align: right; } }
  @media only screen and (min-width: 959px) {
    .txt2 {
      text-align: left;
      position: absolute;
      top: 30px;
      font-family: 'Adobe Caslon Pro Italic';
      width: 100%;
      color: #5f2900;
      padding: 0px;
      width: 600px;
      margin-left: 100px;
      margin-top: -15px; } }
  @media only screen and (min-width: 1025px) {
    .txt2 {
      width: 600px;
      margin-left: 20px;
      margin-top: -10px; } }
  .txt2 h1 {
    font-family: 'Adobe Caslon Pro Bold Italic';
    font-size: 30px; }
    @media only screen and (min-width: 1025px) {
      .txt2 h1 {
        font-size: 36px; } }
    @media only screen and (min-width: 1420px) {
      .txt2 h1 {
        font-size: 42px; } }
  .txt2 p {
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.2; }
    .txt2 p br {
      display: none; }
      @media only screen and (min-width: 1025px) {
        .txt2 p br {
          display: block; } }
    @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
      .txt2 p {
        font-size: 20px; } }
    @media only screen and (min-width: 1025px) {
      .txt2 p {
        font-size: 22px; } }
    @media only screen and (min-width: 1420px) {
      .txt2 p {
        font-size: 27px; } }

.alimentacao {
  height: 100%;
  width: 100%;
  overflow: hidden; }

.alim-fix {
  position: absolute;
  margin-top: -10px;
  top: 40vh;
  left: -20px;
  height: 100px;
  width: calc(100vw + 40px);
  transform-origin: bottom center;
  background-color: transparent;
  animation: float-movement 9s ease-in-out infinite;
  animation-direction: alternate-reverse;
  animation-play-state: running;
  animation-delay: 0;
  mix-blend-mode: color-burn; }

.alim-fix2 {
  position: absolute;
  z-index: 2;
  margin-top: 0px;
  top: 40vh;
  left: 0;
  height: 120px;
  width: 100vw;
  transform-origin: bottom center;
  opacity: 0.2;
  background-color: transparent; }

.alim-fix-bg {
  position: absolute;
  height: 80px;
  padding-top: -10px;
  width: inherit;
  top: 40vh;
  opacity: 0.2; }

.ali-top {
  position: absolute;
  top: -30px;
  left: 0;
  height: 100px;
  z-index: 2;
  width: auto; }

.alimentacao-bg {
  position: relative;
  display: flex;
  height: 25vh;
  width: 100%;
  background-image: none;
  background-size: cover;
  overflow: hidden; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .alimentacao-bg {
      background-image: none;
      position: relative;
      display: flex;
      height: 40vh;
      width: 100%;
      overflow: hidden; } }
  @media only screen and (min-width: 959px) {
    .alimentacao-bg {
      background-image: none;
      position: relative;
      display: flex;
      height: 40vh;
      width: 100%;
      overflow: hidden; } }
  .alimentacao-bg .alimentacao-bg-img {
    position: absolute;
    width: 300%;
    will-change: transform; }
    @media only screen and (max-width: 990px) {
      .alimentacao-bg .alimentacao-bg-img {
        margin-top: 100px; } }
    @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
      .alimentacao-bg .alimentacao-bg-img {
        width: 350%; } }
    @media only screen and (min-width: 959px) {
      .alimentacao-bg .alimentacao-bg-img {
        width: 160%; } }
    @media only screen and (min-width: 1025px) {
      .alimentacao-bg .alimentacao-bg-img {
        max-width: 120%; } }
    @media only screen and (min-width: 1420px) {
      .alimentacao-bg .alimentacao-bg-img {
        max-width: 120%;
        margin-top: 150px; } }

.alimentacao-i2 {
  position: relative;
  display: flex;
  justify-content: center;
  mix-blend-mode: color-burn; }
  .alimentacao-i2 img {
    width: 200px;
    height: 120px; }
  @media only screen and (min-width: 959px) {
    .alimentacao-i2 {
      margin-top: 10px;
      position: absolute;
      right: 30px;
      bottom: 90px;
      mix-blend-mode: color-burn; }
      .alimentacao-i2 img {
        width: 100%;
        height: 100%; } }
  @media only screen and (min-width: 1025px) {
    .alimentacao-i2 {
      position: absolute;
      right: 80px;
      bottom: 100px;
      mix-blend-mode: color-burn; }
      .alimentacao-i2 img {
        width: 120%;
        height: 120%; } }

.alimentacao-w {
  position: relative;
  display: flex;
  width: 100%;
  z-index: -1;
  background-image: url("../img/alimbg.png"), url("../img/bg-header-mob.jpg");
  background-position: center bottom -30px, center;
  background-repeat: no-repeat;
  background-size: contain, cover;
  justify-content: center;
  align-items: stretch; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .alimentacao-w {
      background-image: url("../img/bg-header.jpg");
      justify-content: center;
      background-size: cover;
      align-items: center; } }
  @media only screen and (min-width: 959px) {
    .alimentacao-w {
      height: 60vh;
      padding-top: 100px;
      background-image: url("../img/bg-header.jpg");
      justify-content: center;
      background-size: cover;
      align-items: center;
      height: 60vh; } }
  .alimentacao-w .container {
    width: 100%; }

@media only screen and (min-width: 1420px) {
  .alimentacao-i {
    margin-top: 140px; } }

.img1q3 {
  position: relative;
  height: 100px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  background-image: url("../img/img1q3.png");
  --aspect-ratio: 1/2.5;
  background-repeat: no-repeat;
  background-size: contain; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .img1q3 {
      position: relative;
      margin-bottom: 0px;
      height: 200px;
      width: 500px;
      margin-top: 60px;
      margin-left: 100px; } }
  @media only screen and (min-width: 959px) {
    .img1q3 {
      position: relative;
      margin-bottom: 30px;
      height: 200px;
      width: 600px;
      margin-top: 30px;
      margin-left: 50px; } }
  @media only screen and (min-width: 1420px) {
    .img1q3 {
      width: 710px; } }

.txt3 {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  padding: 10px;
  font-family: 'Adobe Caslon Pro Italic';
  width: 100%;
  color: #5f2900;
  text-align: center; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .txt3 {
      margin-bottom: 40px;
      margin-top: 40px;
      width: 80%;
      padding: 0px;
      margin-left: 10%; } }
  @media only screen and (min-width: 959px) {
    .txt3 {
      margin-bottom: 40px;
      margin-top: 40px;
      margin-left: 100px;
      padding: 20px;
      text-align: left; } }
  @media only screen and (min-width: 1025px) {
    .txt3 {
      margin-top: 40px;
      margin-left: 150px;
      padding: 0px;
      text-align: left;
      width: 800px; } }
  @media only screen and (min-width: 1420px) {
    .txt3 {
      margin-top: 40px;
      margin-left: 150px;
      padding: 0px;
      text-align: left;
      width: 800px; } }
  .txt3 h1 {
    font-family: 'Adobe Caslon Pro Bold Italic';
    font-size: 30px; }
    @media only screen and (min-width: 1025px) {
      .txt3 h1 {
        font-size: 36px; } }
    @media only screen and (min-width: 1420px) {
      .txt3 h1 {
        font-size: 42px; } }
  .txt3 p {
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.2; }
    .txt3 p br {
      display: none; }
      @media only screen and (min-width: 959px) {
        .txt3 p br {
          display: block; } }
    @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
      .txt3 p {
        font-size: 20px; } }
    @media only screen and (min-width: 1025px) {
      .txt3 p {
        font-size: 22px; } }
    @media only screen and (min-width: 1420px) {
      .txt3 p {
        font-size: 27px; } }

.img3q3 {
  position: absolute;
  height: 160px;
  width: 100%;
  right: 0px;
  bottom: -70px;
  background-image: url("../img/img3q33.png");
  --aspect-ratio: 1/2.5;
  background-repeat: repeat;
  background-size: contain;
  animation: float-movement 9s ease-in-out infinite;
  animation-direction: alternate-reverse;
  animation-play-state: running;
  animation-delay: 0;
  transform-origin: bottom center; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .img3q3 {
      height: 280px;
      bottom: -160px; } }
  @media only screen and (min-width: 959px) {
    .img3q3 {
      height: 180px;
      bottom: -80px; } }

@keyframes float-movement {
  0% {
    transform: skewX(0.1deg); }
  35% {
    transform: skewX(3deg); }
  70% {
    transform: skewX(-3deg); }
  100% {
    transform: skewX(0deg); } }

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  z-index: 2001;
  position: fixed;
  margin: auto;
  top: 12px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 50px;
  overflow: hidden; }

.pace .pace-progress {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  display: block;
  position: absolute;
  right: 100%;
  margin-right: -7px;
  width: 93%;
  top: 7px;
  height: 14px;
  font-size: 12px;
  background: #5f2900;
  color: #5f2900;
  line-height: 60px;
  font-weight: bold;
  font-family: Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-box-shadow: 120px 0 #fff, 240px 0 #fff;
  -ms-box-shadow: 120px 0 #fff, 240px 0 #fff;
  box-shadow: 120px 0 #fff, 240px 0 #fff; }

.pace .pace-progress:after {
  content: attr(data-progress-text);
  display: inline-block;
  position: fixed;
  width: 45px;
  text-align: right;
  right: 0;
  padding-right: 16px;
  top: 4px; }

.pace .pace-progress[data-progress-text="0%"]:after {
  right: -200px; }

.pace .pace-progress[data-progress-text="1%"]:after {
  right: -198.14px; }

.pace .pace-progress[data-progress-text="2%"]:after {
  right: -196.28px; }

.pace .pace-progress[data-progress-text="3%"]:after {
  right: -194.42px; }

.pace .pace-progress[data-progress-text="4%"]:after {
  right: -192.56px; }

.pace .pace-progress[data-progress-text="5%"]:after {
  right: -190.7px; }

.pace .pace-progress[data-progress-text="6%"]:after {
  right: -188.84px; }

.pace .pace-progress[data-progress-text="7%"]:after {
  right: -186.98px; }

.pace .pace-progress[data-progress-text="8%"]:after {
  right: -185.12px; }

.pace .pace-progress[data-progress-text="9%"]:after {
  right: -183.26px; }

.pace .pace-progress[data-progress-text="10%"]:after {
  right: -181.4px; }

.pace .pace-progress[data-progress-text="11%"]:after {
  right: -179.54px; }

.pace .pace-progress[data-progress-text="12%"]:after {
  right: -177.68px; }

.pace .pace-progress[data-progress-text="13%"]:after {
  right: -175.82px; }

.pace .pace-progress[data-progress-text="14%"]:after {
  right: -173.96px; }

.pace .pace-progress[data-progress-text="15%"]:after {
  right: -172.1px; }

.pace .pace-progress[data-progress-text="16%"]:after {
  right: -170.24px; }

.pace .pace-progress[data-progress-text="17%"]:after {
  right: -168.38px; }

.pace .pace-progress[data-progress-text="18%"]:after {
  right: -166.52px; }

.pace .pace-progress[data-progress-text="19%"]:after {
  right: -164.66px; }

.pace .pace-progress[data-progress-text="20%"]:after {
  right: -162.8px; }

.pace .pace-progress[data-progress-text="21%"]:after {
  right: -160.94px; }

.pace .pace-progress[data-progress-text="22%"]:after {
  right: -159.08px; }

.pace .pace-progress[data-progress-text="23%"]:after {
  right: -157.22px; }

.pace .pace-progress[data-progress-text="24%"]:after {
  right: -155.36px; }

.pace .pace-progress[data-progress-text="25%"]:after {
  right: -153.5px; }

.pace .pace-progress[data-progress-text="26%"]:after {
  right: -151.64px; }

.pace .pace-progress[data-progress-text="27%"]:after {
  right: -149.78px; }

.pace .pace-progress[data-progress-text="28%"]:after {
  right: -147.92px; }

.pace .pace-progress[data-progress-text="29%"]:after {
  right: -146.06px; }

.pace .pace-progress[data-progress-text="30%"]:after {
  right: -144.2px; }

.pace .pace-progress[data-progress-text="31%"]:after {
  right: -142.34px; }

.pace .pace-progress[data-progress-text="32%"]:after {
  right: -140.48px; }

.pace .pace-progress[data-progress-text="33%"]:after {
  right: -138.62px; }

.pace .pace-progress[data-progress-text="34%"]:after {
  right: -136.76px; }

.pace .pace-progress[data-progress-text="35%"]:after {
  right: -134.9px; }

.pace .pace-progress[data-progress-text="36%"]:after {
  right: -133.04px; }

.pace .pace-progress[data-progress-text="37%"]:after {
  right: -131.18px; }

.pace .pace-progress[data-progress-text="38%"]:after {
  right: -129.32px; }

.pace .pace-progress[data-progress-text="39%"]:after {
  right: -127.46px; }

.pace .pace-progress[data-progress-text="40%"]:after {
  right: -125.6px; }

.pace .pace-progress[data-progress-text="41%"]:after {
  right: -123.74px; }

.pace .pace-progress[data-progress-text="42%"]:after {
  right: -121.88px; }

.pace .pace-progress[data-progress-text="43%"]:after {
  right: -120.02px; }

.pace .pace-progress[data-progress-text="44%"]:after {
  right: -118.16px; }

.pace .pace-progress[data-progress-text="45%"]:after {
  right: -116.3px; }

.pace .pace-progress[data-progress-text="46%"]:after {
  right: -114.44px; }

.pace .pace-progress[data-progress-text="47%"]:after {
  right: -112.58px; }

.pace .pace-progress[data-progress-text="48%"]:after {
  right: -110.72px; }

.pace .pace-progress[data-progress-text="49%"]:after {
  right: -108.86px; }

.pace .pace-progress[data-progress-text="50%"]:after {
  right: -107px; }

.pace .pace-progress[data-progress-text="51%"]:after {
  right: -105.14px; }

.pace .pace-progress[data-progress-text="52%"]:after {
  right: -103.28px; }

.pace .pace-progress[data-progress-text="53%"]:after {
  right: -101.42px; }

.pace .pace-progress[data-progress-text="54%"]:after {
  right: -99.56px; }

.pace .pace-progress[data-progress-text="55%"]:after {
  right: -97.7px; }

.pace .pace-progress[data-progress-text="56%"]:after {
  right: -95.84px; }

.pace .pace-progress[data-progress-text="57%"]:after {
  right: -93.98px; }

.pace .pace-progress[data-progress-text="58%"]:after {
  right: -92.12px; }

.pace .pace-progress[data-progress-text="59%"]:after {
  right: -90.26px; }

.pace .pace-progress[data-progress-text="60%"]:after {
  right: -88.4px; }

.pace .pace-progress[data-progress-text="61%"]:after {
  right: -86.53999999999999px; }

.pace .pace-progress[data-progress-text="62%"]:after {
  right: -84.68px; }

.pace .pace-progress[data-progress-text="63%"]:after {
  right: -82.82px; }

.pace .pace-progress[data-progress-text="64%"]:after {
  right: -80.96000000000001px; }

.pace .pace-progress[data-progress-text="65%"]:after {
  right: -79.1px; }

.pace .pace-progress[data-progress-text="66%"]:after {
  right: -77.24px; }

.pace .pace-progress[data-progress-text="67%"]:after {
  right: -75.38px; }

.pace .pace-progress[data-progress-text="68%"]:after {
  right: -73.52px; }

.pace .pace-progress[data-progress-text="69%"]:after {
  right: -71.66px; }

.pace .pace-progress[data-progress-text="70%"]:after {
  right: -69.8px; }

.pace .pace-progress[data-progress-text="71%"]:after {
  right: -67.94px; }

.pace .pace-progress[data-progress-text="72%"]:after {
  right: -66.08px; }

.pace .pace-progress[data-progress-text="73%"]:after {
  right: -64.22px; }

.pace .pace-progress[data-progress-text="74%"]:after {
  right: -62.36px; }

.pace .pace-progress[data-progress-text="75%"]:after {
  right: -60.5px; }

.pace .pace-progress[data-progress-text="76%"]:after {
  right: -58.64px; }

.pace .pace-progress[data-progress-text="77%"]:after {
  right: -56.78px; }

.pace .pace-progress[data-progress-text="78%"]:after {
  right: -54.92px; }

.pace .pace-progress[data-progress-text="79%"]:after {
  right: -53.06px; }

.pace .pace-progress[data-progress-text="80%"]:after {
  right: -51.2px; }

.pace .pace-progress[data-progress-text="81%"]:after {
  right: -49.34px; }

.pace .pace-progress[data-progress-text="82%"]:after {
  right: -47.480000000000004px; }

.pace .pace-progress[data-progress-text="83%"]:after {
  right: -45.62px; }

.pace .pace-progress[data-progress-text="84%"]:after {
  right: -43.76px; }

.pace .pace-progress[data-progress-text="85%"]:after {
  right: -41.9px; }

.pace .pace-progress[data-progress-text="86%"]:after {
  right: -40.04px; }

.pace .pace-progress[data-progress-text="87%"]:after {
  right: -38.18px; }

.pace .pace-progress[data-progress-text="88%"]:after {
  right: -36.32px; }

.pace .pace-progress[data-progress-text="89%"]:after {
  right: -34.46px; }

.pace .pace-progress[data-progress-text="90%"]:after {
  right: -32.6px; }

.pace .pace-progress[data-progress-text="91%"]:after {
  right: -30.740000000000002px; }

.pace .pace-progress[data-progress-text="92%"]:after {
  right: -28.880000000000003px; }

.pace .pace-progress[data-progress-text="93%"]:after {
  right: -27.02px; }

.pace .pace-progress[data-progress-text="94%"]:after {
  right: -25.16px; }

.pace .pace-progress[data-progress-text="95%"]:after {
  right: -23.3px; }

.pace .pace-progress[data-progress-text="96%"]:after {
  right: -21.439999999999998px; }

.pace .pace-progress[data-progress-text="97%"]:after {
  right: -19.58px; }

.pace .pace-progress[data-progress-text="98%"]:after {
  right: -17.72px; }

.pace .pace-progress[data-progress-text="99%"]:after {
  right: -15.86px; }

.pace .pace-progress[data-progress-text="100%"]:after {
  right: -14px; }

.pace .pace-activity {
  position: absolute;
  width: 100%;
  height: 28px;
  z-index: 2001;
  box-shadow: inset 0 0 0 2px #5f2900, inset 0 0 0 7px #FFF;
  border-radius: 10px; }

.pace.pace-inactive {
  display: none; }

.cover {
  position: fixed;
  opacity: 0.91;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/bg-header.jpg");
  background-size: cover; }

.maturacao {
  position: relative;
  background-image: url("../img/bg-header-mob.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
  padding-bottom: 25px; }
  @media only screen and (min-width: 959px) {
    .maturacao {
      height: 100vh;
      padding-bottom: 0px;
      min-height: 100% !important;
      background-image: url("../img/bg-header.jpg");
      background-position: 0vw 0vh; } }

.maturacao-bg {
  position: relative;
  display: flex;
  height: 25vh;
  width: 100%;
  z-index: 20;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  object-fit: none;
  min-width: 100%;
  overflow: hidden;
  backface-visibility: hidden; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .maturacao-bg {
      height: 40vh;
      justify-content: center;
      align-items: baseline; } }
  @media only screen and (min-width: 1025px) {
    .maturacao-bg {
      height: 40vh;
      justify-content: center;
      align-items: baseline; } }
  @media only screen and (min-width: 959px) {
    .maturacao-bg {
      justify-content: center;
      align-items: center;
      height: 40vh; } }
  @media only screen and (min-width: 1420px) {
    .maturacao-bg {
      justify-content: start;
      align-items: center;
      height: 40vh; } }

.maturacao-bg-imgm {
  position: absolute;
  height: 400px;
  width: auto;
  margin-top: 90px;
  margin-left: 100px; }

.maturacao-bg-img {
  backface-visibility: hidden;
  position: absolute;
  height: auto;
  width: 2000px;
  will-change: transform; }
  @media only screen and (min-width: 959px) {
    .maturacao-bg-img {
      bottom: -211px;
      min-width: 2166px;
      max-width: 2166px; } }
  @media only screen and (min-width: 1025px) {
    .maturacao-bg-img {
      bottom: -170px;
      min-width: 2166px;
      max-width: 2166px; } }
  @media only screen and (min-width: 1420px) {
    .maturacao-bg-img {
      bottom: inherit;
      min-width: 2166px;
      max-width: 2166px;
      min-height: 57.78vh !important; } }

.bg-matu-left {
  position: absolute; }
  @media only screen and (min-width: 959px) {
    .bg-matu-left {
      left: 0px;
      width: calc((100vw - 1025px) + (215px * 2.8)) !important;
      max-width: 1000px;
      height: auto; } }
  @media only screen and (min-width: 1025px) {
    .bg-matu-left {
      left: 0px;
      width: calc((100vw - 1025px) + (225px * 1.5)) !important;
      max-width: 530px;
      height: auto; } }
  @media only screen and (min-width: 1420px) {
    .bg-matu-left {
      height: 4.65vh;
      width: auto !important;
      max-width: 100%; } }

.maturacao-bg.lazy-bg-loaded {
  background-image: url("../img/bg-maturacao2.jpg"); }

.maturacao-w.lazy-bg-loaded {
  background-image: url("../img/bg-header-mob.jpg"); }

.maturacao-w {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .maturacao-w {
      height: 60vh; } }
  @media only screen and (min-width: 959px) {
    .maturacao-w {
      padding-bottom: 0px;
      align-items: center;
      flex-direction: row;
      height: 60vh; } }
  @media only screen and (min-width: 1025px) {
    .maturacao-w {
      padding-bottom: 0px;
      align-items: center;
      flex-direction: row;
      height: 60vh; } }

.img1q4m {
  height: 25vh;
  width: 100vw;
  margin-top: 20px;
  margin-bottom: 40px;
  background-image: url("../img/img111q4.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 9999; }

.bg-matuw {
  width: 100%;
  max-height: 60vh; }

@media only screen and (min-width: 959px) {
  .bg-matu {
    position: absolute;
    top: 37vh;
    width: 2270px !important;
    height: 68vh;
    max-height: 68vh;
    max-width: 2270px;
    left: calc(((100vw - 1365px) / 2) - 467px);
    overflow: hidden;
    opacity: 1; } }

@media only screen and (min-width: 1025px) {
  .bg-matu {
    position: absolute;
    top: 37vh;
    width: 2270px !important;
    left: calc(((100vw - 1365px) / 2) - 467px);
    height: 68vh;
    max-height: 68vh;
    max-width: 2270px;
    overflow: hidden;
    opacity: 1; } }

@media only screen and (min-width: 1420px) {
  .bg-matu {
    position: absolute;
    top: 37vh;
    left: -120px;
    min-width: 2360px !important;
    height: 63vh;
    max-height: 63vh;
    max-width: 2360px;
    overflow: hidden;
    opacity: 1;
    z-index: 0; } }

.container-flex {
  z-index: 9999;
  display: flex;
  flex-direction: column;
  min-height: max-content;
  justify-content: center;
  flex: 2 1 auto; }
  @media only screen and (min-width: 767px) and (max-width: 1025px) and (orientation: portrait) {
    .container-flex {
      height: 50vh; } }
  @media only screen and (min-width: 959px) {
    .container-flex {
      height: 50vh;
      flex-direction: row; } }
  @media only screen and (min-width: 1025px) {
    .container-flex {
      height: 60vh;
      flex-direction: row; } }
  @media only screen and (min-width: 1420px) {
    .container-flex {
      height: 60vh;
      flex-direction: row;
      justify-content: stretch; } }

.maturacao-i {
  position: relative;
  z-index: 9999; }
  @media only screen and (min-width: 1025px) {
    .maturacao-i {
      width: 100%;
      height: 100%; } }

.img1q4 {
  position: absolute;
  height: 32%;
  left: 115vh;
  top: 40vh;
  z-index: 9999; }
  @media only screen and (min-width: 959px) {
    .img1q4 {
      left: calc(((100vw - 1365px) / 2) + 800px);
      height: 30%;
      top: calc(80vh + 10%); } }
  @media only screen and (min-width: 1025px) {
    .img1q4 {
      height: 250px;
      top: calc(80vh - 100px);
      left: calc(((100vw - 1365px) / 2) + 800px); } }
  @media only screen and (min-width: 1420px) {
    .img1q4 {
      height: 38%;
      top: calc(80vh - 19%);
      left: 1050px;
      z-index: 9999; } }

.img1q4mob {
  height: 100px;
  width: 100vw;
  background-image: url("../img/img1q4.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

.txt4 {
  overflow: visible;
  position: relative;
  font-family: 'Adobe Caslon Pro Italic';
  text-align: center;
  padding: 20px;
  color: #5f2900; }
  @media only screen and (min-width: 959px) {
    .txt4 {
      margin-top: 0px;
      text-align: left;
      padding: 30px; } }
  @media only screen and (min-width: 1420px) {
    .txt4 {
      margin-left: -10%;
      margin-top: -20px; } }
  .txt4 h1 {
    font-family: 'Adobe Caslon Pro Bold Italic';
    font-size: 32px; }
    @media only screen and (min-width: 1025px) {
      .txt4 h1 {
        font-size: 36px; } }
    @media only screen and (min-width: 1420px) {
      .txt4 h1 {
        font-size: 42px; } }
  .txt4 p {
    font-size: 18px;
    margin-top: 15px;
    line-height: 1.2; }
    @media only screen and (min-width: 1025px) {
      .txt4 p {
        margin-top: 10px;
        line-height: 1.2;
        font-size: 22px; } }
    @media only screen and (min-width: 1420px) {
      .txt4 p {
        font-size: 27px; } }

.cont-matu {
  z-index: 9999;
  max-width: 100vw;
  width: 100%;
  height: 60vh;
  display: grid;
  justify-content: center;
  align-items: center; }
  @media only screen and (min-width: 959px) {
    .cont-matu {
      background-image: none;
      grid-template: ". . ." 1fr ". txt ." auto ". . ." 1fr / 1fr auto 4fr; } }
  @media only screen and (min-width: 1420px) {
    .cont-matu {
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template: ". . ." 1fr ". txt ." auto ". . ." 1fr / 1fr auto 4fr; } }

.cont-matu-w {
  grid-area: txt;
  position: relative;
  width: 100%; }

.img2q4 {
  position: absolute;
  margin-left: 50px;
  bottom: 0px;
  height: 100%;
  width: 300px;
  z-index: 9999;
  background-image: url("../img/img2q4.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

#contactForm {
  display: none;
  z-index: 99999;
  border: 6px solid #5f2900;
  padding: 2em;
  width: 500px;
  text-align: center;
  background: #5f2900;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%); }

.modal p {
  color: #fff;
  font-family: 'Adobe Caslon Pro Italic';
  margin-bottom: 5px;
  font-size: 18px; }

.contact-bg {
  display: none;
  z-index: 99998;
  position: fixed;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.9); }

.contacts {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center; }
  .contacts img {
    height: 30px;
    margin: 10px 5px; }

.animalogo, .animado6, .animado7, .animado8, .animado5, .parallax-bg,
.animaq, .animaq2, .animaq3 {
  will-change: transform, opacity; }

body {
  width: 100%;
  overflow-x: hidden;
  height: initial; }

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("../img/bg-header-mob.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  will-change: transform; }
  @media only screen and (min-width: 959px) {
    body:before {
      background-image: url("../img/bg-header.jpg"); } }

p {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none; }

::-webkit-scrollbar {
  display: none; }

section {
  will-change: transform;
  max-height: 100% !important;
  min-height: 100%;
  overflow: hidden;
  overflow-x: hidden; }
  @media only screen and (min-width: 959px) {
    section {
      height: 100vh !important; } }

.pagination {
  z-index: 1999;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  list-style: none;
  flex-direction: column;
  outline: none; }
  @media only screen and (min-width: 959px) {
    .pagination {
      right: 30px; } }
  .pagination li {
    z-index: 9999;
    position: relative;
    margin: 5px 0;
    background: #fff;
    border: 1px #5f2900 solid;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    color: #5f2900;
    transition: all .2s ease;
    outline: none; }
    @media only screen and (min-width: 959px) {
      .pagination li {
        cursor: pointer; }
        .pagination li:hover {
          transform: scale(1.2); }
          .pagination li:hover .hover-text {
            opacity: 1; } }
    .pagination li :active {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent; }
    .pagination li :active, .pagination li :focus {
      outline: 0;
      border: none;
      --moz-outline-style: none; }

li.active {
  background: #5f2900;
  transform: scale(1.2);
  transition: all .3s ease;
  outline: none; }

.hover-text {
  z-index: 999;
  position: absolute;
  right: 5px;
  top: -3px;
  opacity: 0;
  font-size: 12px;
  color: #5f2900;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  padding-right: 10px;
  white-space: nowrap;
  font-family: 'Adobe Caslon Pro Bold Italic';
  outline: none; }

.desktop {
  display: none; }
  @media only screen and (min-width: 959px) {
    .desktop {
      display: block; } }

.mobile {
  display: block; }
  @media only screen and (min-width: 959px) {
    .mobile {
      display: none; } }
