.agrofloresta {
    
    width: 100%;
    overflow: hidden;
    background-image: url(../img/bg-header-mob.jpg);
    background-size: cover;

    @include responsivo(medium-screens) {
        background-image: url(../img/bg-header.jpg);
    }
}

.agrofloresta-bg {

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28vh;
    width: 100%;

    @include responsivo(tablet) {
        background-image: none;
        height: 50vh;
    }

    @include responsivo(medium-screens) {

        background-image: none;
        height: 50vh;
    }
    @include responsivo(screens) {

        background-image: none;
        height: 40vh;
    }

    overflow: hidden;


    .agrofloresta-bg-img {
        will-change: transform;
        position: absolute;
        max-width: 150%;
margin-top:150px;
        left: 0px;
        @include responsivo(tablet) {
            display: block;
            margin-top: 300px;
            max-width: 180%;
        }
        @include responsivo(medium-screens) {
            display: block;
            margin-top: 400px;
            max-width: 100%;
        }

        @include responsivo(wide-screens) {
            margin-top: 580px;

        }
    }

    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 1s;
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

.agrofloresta-bg.lazy-bg-loaded {

   


    @include responsivo(medium-screens) {

        background-image: none;
    }
}

.agrofloresta-i2-mob {
    position: relative;
    margin-top: 50px;
    margin-bottom:-30px;
    margin-left:100px;
    width:140px;
    height: 140px;
    background-image: url(../img/frutas.png);
    background-size: contain;
    background-repeat: no-repeat;
    mix-blend-mode: color-burn;
}
.agrofloresta-i-mob {
    position:relative;
    height: 250px;
    width: 120%;
    margin-left:10%;
    margin-top: -150px;
    margin-bottom:30px;
    background-image: url("../img/img1q2-mob.png");
    --aspect-ratio: 1/2.5;
    background-repeat: no-repeat;
    background-size: contain;
    mix-blend-mode: color-burn;
}

.agrofloresta-i2 {
    position: relative;
 
    mix-blend-mode: color-burn;
    img {
        
        mix-blend-mode: color-burn;
       
        position: absolute;
        margin-left:80px;

        @include responsivo(medium-screens) {
            position: absolute;
            width:200px;
            margin-left:100px;

        }
        @include responsivo(screens) {
            position: absolute;
            width: auto; 
            margin-left:20px;


        }
        


    }
   

    @include responsivo(medium-screens) {
        position: absolute;
        right: 35%;
        top: 30px;
    }
}

.agrofloresta-w {
    will-change: opacity;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;



    @include responsivo(medium-screens) {
        padding-left: 100px;
        max-height: 541px;
    }
    @include responsivo(screens) {
        padding-left: 100px;
        max-height: 541px;
    }


    @include responsivo(wide-screens) {
        padding-left: 50px;
    }

    .container {

        display: flex;
        justify-content: center;
        background-size: cover;
        align-items: center;
        flex-direction: column;
  

        @include responsivo(medium-screens) {
            align-items: center;
            flex-direction: row;
      

        }
 
    }
}

.agrofloresta-if {
    display: none;
 
    @include responsivo(tablet) {
        display: flex;
        flex-direction: column;
        position: relative;
        left: 20%;
        top: -467px;
        width: 180%;
    }
    @include responsivo(medium-screens) {
        display: flex;
        flex-direction: column;
        position: relative;
        left: -11%;
        top: -150px;
        width: 180%;
    }

    @include responsivo(wide-screens) {
        left: -25%;
        top: -200px;
        width: 180%;
    }

}


.agrofloresta-f {

    height: 300px;
    overflow: hidden;

    @include responsivo(wide-screens) {
        height: 400px;
        margin-left:-4px;
    }

    .araucaria {
        z-index: 10;
        transform: rotate(3deg);
        width: 200px;
        left: 58px;
        top: -78px;

        img {
            width: 500px;
            
            height: auto;
            @include responsivo(tablet) {
                width: 660px;
                    margin-top:-50px;
            }
            @include responsivo(screens) {
                width: 600px;
            }
            @include responsivo(wide-screens) {
                width: 786px;
            }
        }

        @include responsivo(wide-screens) {


            width: 200px;
            left: 58px;
            top: -78px;
        }
    }
}

.agrofloresta-i {
    
    position: relative;
    left: 50px;
    top: 0px;
    right: 0;

    @include responsivo(wide-screens) {
        left: 50px;
        top: 0px;
        right: 0;
    }


}

.img1q2 {

    height: 541px;
    width: 100%;
    background-image: url(../img/img0q2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-blend-mode: color-burn;


    margin-left: -5px;

    @include responsivo(wide-screens) {
        height: 541px;
        width: 180%;

        background-size: auto, cover;

    }


}

.txt2 {
    position: relative;
    top: 20px;
    font-family: 'Adobe Caslon Pro Italic';
    width: 100%;
    color: $color1;
    text-align: center;
    padding: 15px;
    
    @include responsivo(tablet) {
       text-align:right;

    }
    @include responsivo(medium-screens) {
        text-align: left;
        position: absolute;
        top: 30px;
        font-family: 'Adobe Caslon Pro Italic';
        width: 100%;
        color: $color1;
        padding: 0px;
        width: 600px;
        margin-left: 100px;
        margin-top: -15px;

    }

    @include responsivo(screens) {
        width: 600px;
        margin-left: 20px;
        margin-top: -10px;

    }

    h1 {
        font-family: 'Adobe Caslon Pro Bold Italic';
        font-size: 30px;
       
        @include responsivo(screens) {
            font-size: 36px;
        }

        @include responsivo(wide-screens) {
            font-size: 42px;
        }
    }

    p {
        font-size: 18px;
        margin-top: 10px;
        line-height: 1.2;
        br {  display: none;
            @include responsivo(screens) {
                display: block;
            }}

        
        @include responsivo(tablet) {
      
            font-size: 20px;
        }
        
        @include responsivo(screens) {
            font-size: 22px;
        }

        @include responsivo(wide-screens) {
            font-size: 27px;
        }
    }
}