@font-face {
    font-family: 'Adobe Caslon Pro Regular';
    src: url('../fonts/ACaslonPro-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ACaslonPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ACaslonPro-Regular.woff2') format('woff2'), /* Modern Browsers */
        url('../fonts/ACaslonPro-Regular.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ACaslonPro-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ACaslonPro-Regular.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Adobe Caslon Pro Italic';
    src: url('../fonts/ACaslonPro-Italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ACaslonPro-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ACaslonPro-Italic.woff2') format('woff2'), /* Modern Browsers */
        url('../fonts/ACaslonPro-Italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ACaslonPro-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ACaslonPro-Italic.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Adobe Caslon Pro Bold Italic';
    src: url('../fonts/ACaslonPro-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ACaslonPro-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ACaslonPro-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
        url('../fonts/ACaslonPro-BoldItalic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ACaslonPro-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ACaslonPro-BoldItalic.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}