
.header {
 
        height: 100vh!important;

    position: relative;
 
    background-size: cover;
    overflow: hidden;
    z-index: 999;


    @include responsivo(screens) {}

    @include responsivo(wide-screens) {}
}
// 113078
.fullscreen-bg__video {
    position: absolute;
    left: 0;
   
    min-width: 100%;
    min-height: 100%;
opacity: 0.4;

}

.header-logo-h {
    display: flex;
    padding-top: 20px;
    flex-direction: column;

}

.agrofloresta-ww {
    min-height: 60vh;
}

.header-logo {
    will-change: opacity;
    position: relative;
    width: 300px;
    height: 280px;
    max-height: calc(100vh - 280px);
    max-width: 90%;
    background-image: url("../img/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
    transition: all 0.5s ease;
    pointer-events: none;
        
    @include responsivo(tablet) {
        width: 450px;
        height: 400px;
    }
    @include responsivo(medium-screens) {
        width: 400px;
        height: 360px;
    }

    @include responsivo(screens) {
      
    }

    @include responsivo(wide-screens) {
        width: 500px;
        height: 460px;
        max-height: calc(100vh - 480px);
     
    }

}

.montanhas {
    will-change: transform;
    position: absolute;
    bottom: 0px;
    height: 220px;
    width: 100%;
    min-width: 100vw;
    background-image: url("../img/montanha-mob.png");
    background-size: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    

    @include responsivo(tablet) {
        background-image: url("../img/montanha.png");
        background-size: cover;
        height:280px;
    }
    @include responsivo(medium-screens) {
        background-image: url("../img/montanha.png");
        background-size: cover;
        width: 1200px;
    }
    @include responsivo(wide-screens) {
        height: 370px;
    }



}

.montanhas-ilustracao {
    will-change: transform;
    position: absolute;
    bottom: 50px;
    height: 280px;
    width: 1200px;
    min-width: 100vw;
    left:-300px;
    background-image: url(../img/montanha-ilustracao.png);
    background-size: cover;
    mix-blend-mode: color-burn;
    @include responsivo(tablet) {
        bottom: 100px;
      
    }
    @include responsivo(medium-screens) {
        bottom: 0;
        left:0;
    }
    @include responsivo(wide-screens) {
        bottom: 180px;
        left:0;
    }
}

